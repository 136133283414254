import {
  genderOptions,
  booleanOptions,
  smokeOptions,
  workDistanceOptions,
} from "../../../utils/staticData";

import { errorMessage } from "../../../utils/error";
import "./formCalcPrice.styles.scss";
import { ButtonDS, GlobalIcon, InputDS, SelectDS } from "get-life-storybook-ts";

export default function FormCalcPriceFR(props) {
  const {
    buttonCalculate,
    capitalLimits,
    death,
    disability,
    error,
    handleSubmitOrganic,
    setCapital,
    workingProfesionList,
    setValue,
    translate,
    values,
    showResults,
    setError,
    validateBirthdate
  } = props;

  const isDisability = disability !== undefined && !disability;

  let form = values;
  if (isDisability) {
    form = {
      ...form,
      insuranceType: { value: "basic", label: translate("info.death") },
    };
  }

  const clearInputError = (field) => {
    if (error) {
      const updatedError = [...error];
      const errorItem = updatedError.find((e) => e.field === field);

      if (errorItem) {
        errorItem.message = "";
        errorItem.field = "";
        setError(updatedError);
      }
    }
  };

  return (
    <>
      <div className="newDashboard-tariffifierGrid">
        <div className="newDashboard-tariffifierGridColumn">
          <InputDS
            label={translate("calculator.label.birthday")}
            type="date"
            onChange={(e) => setValue("date", e.target.value)}
            onBlur={() => {
              clearInputError("date")
              validateBirthdate();
            }}
            value={form["date"]}
            error={errorMessage(error, "date")}
            helper={translate("dashboard.calculator.maxAge")}
            icon="CalendarEventLineIcon"
          />
          <InputDS
            label={translate("calculator.label.zipcode")}
            onChange={(e) => setValue("zipCode", e.target.value)}
            onBlur={() => clearInputError("zipCode")}
            value={form["zipCode"]}
            error={errorMessage(error, "zipCode")}
            placeholder={translate("calculator.placeholder.zipcode")}
            icon="MapPinIcon"
          />
          <SelectDS
            options={genderOptions(translate)}
            value={form["gender"]?.value ?? ""}
            onChange={(e) => {
              const value = e.target.value;
              const options = genderOptions(translate);
              setValue(
                "gender",
                options.find((e) => e.value === value)
              );
              clearInputError("gender")
            }}
            label={translate("calculator.label.gender")}
            placeholder={translate("calculator.placeholder.gender")}
            error={errorMessage(error, "gender")}
          />
          <InputDS
            label={translate("calculator.label.phone")}
            onChange={(e) => setValue("phone", e.target.value)}
            onBlur={() => clearInputError("phone")}
            value={form["phone"]}
            error={errorMessage(error, "phone")}
            placeholder={translate("calculator.placeholder.phone")}
            icon="DeviceMobile"
          />
          <InputDS
            label={translate("calculator.label.referenceName")}
            onChange={(e) => setValue("referenceName", e.target.value)}
            onBlur={() => clearInputError("referenceName")}
            value={form["referenceName"]}
            error={errorMessage(error, "referenceName")}
            placeholder={translate("calculator.placeholder.referenceName")}
            icon="UserSearchIcon"
          />
          <InputDS
            label={translate("calculator.label.email")}
            onChange={(e) => setValue("email", e.target.value)}
            onBlur={() => clearInputError("email")}
            value={form["email"]}
            error={errorMessage(error, "email")}
            placeholder={translate("calculator.placeholder.email")}
            icon="MailIcon"
          />
          <SelectDS
            options={smokeOptions(translate)}
            value={form["smoker"]?.value ?? ""}
            onChange={(e) => {
              const value = e.target.value;
              const options = smokeOptions(translate);
              setValue(
                "smoker",
                options.find((e) => e.value === value)
              );
              clearInputError("smoker")
            }}
            label={translate("calculator.label.smoker")}
            placeholder={translate("calculator.placeholder.smoker")}
            error={errorMessage(error, "smoker")}
            helper={translate("calculator.helper.smoker")}
            icon="SmokingIcon"
          />
          <SelectDS
            options={workingProfesionList}
            value={form["workingProfesion"]?.value ?? ""}
            onChange={(e) => {
              const value = e.target.value;
              const options = workingProfesionList;
              setValue(
                "workingProfesion",
                options.find((e) => e.value === value)
              );
              clearInputError("workingProfesion")
            }}
            label={translate("calculator.label.workingCategoty")}
            placeholder={translate("calculator.placeholder.workingCategoty")}
            error={errorMessage(error, "workingProfesion")}
            icon="SearchIcon"
          />
          <SelectDS
            options={workDistanceOptions(translate)}
            value={form["workDistance"]?.value ?? ""}
            onChange={(e) => {
              const value = e.target.value;
              const options = workDistanceOptions(translate);
              setValue(
                "workDistance",
                options.find((e) => e.value === value)
              );
              clearInputError("workDistance")
            }}
            label={translate("calculator.label.workDistance")}
            placeholder={translate("calculator.placeholder.workDistance")}
            error={errorMessage(error, "workDistance")}
            helper={translate("calcualtor.helper.workDistance")}
            icon="RulerIcon"
          />
          <SelectDS
            options={booleanOptions(translate)}
            value={form["workHeights"]?.value ?? ""}
            onChange={(e) => {
              const value = e.target.value;
              const options = booleanOptions(translate);
              setValue(
                "workHeights",
                options.find((e) => e.value === value)
              );
              clearInputError("workHeights")
            }}
            label={translate("calculator.label.workHeights")}
            placeholder={translate("calculator.placeholder.workHeights")}
            error={errorMessage(error, "workHeights")}
            icon="ArrowTopBarIcon"
          />
          <SelectDS
            options={booleanOptions(translate)}
            value={form["workTools"]?.value ?? ""}
            onChange={(e) => {
              const value = e.target.value;
              const options = booleanOptions(translate);
              setValue(
                "workTools",
                options.find((e) => e.value === value)
              );
              clearInputError("workTools")
            }}
            label={translate("calculator.label.workTools")}
            placeholder={translate("calculator.placeholder.workTools")}
            error={errorMessage(error, "workTools")}
            icon="WeightIcon"
          />
        </div>
        <div className="newDashboard-tariffifierGridColumn">
          <InputDS
            type="number"
            label={translate("calculator.label.capital")}
            name="capital"
            value={form["capital"]}
            onChange={(e) => {
              const value = !e.target.value
                ? capitalLimits.minCapital
                : e.target.value;
              setCapital(value, setValue);
            }}
            onBlur={() => {
              let value = form["capital"];
              if (value > capitalLimits.maxCapital) {
                value = capitalLimits.maxCapital;
              }
              if (value < capitalLimits.minCapital) {
                value = capitalLimits.minCapital;
              }
              setCapital(value, setValue);
            }}
            error={death && errorMessage(error, "capital")}
            disabled={!form["capital"]}
            icon="CurrencyEuroIcon"
            onKeyDown={(e) => {
              if (e.key === "," || e.key === ".") e.preventDefault();
            }}
            helper={
              capitalLimits.minCapital && capitalLimits.maxCapital
                ? `${translate("edit.projects.minimum")} ${new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    maximumFractionDigits: 0,
                  }).format(
                    capitalLimits.minCapital
                  )}, ${translate("edit.projects.maximum")} ${new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    maximumFractionDigits: 0,
                  }).format(capitalLimits.maxCapital)}`
                : null
            }
            step={5000}
            min={capitalLimits.minCapital}
            max={capitalLimits.maxCapital}
          />
          {form["date"] ? (
            <div className="flex flex-col gap-[8px]">
              <div>
                <p className="BodyL text-[#424242] mb-[16px]">
                  {translate("calculator.label.insuranceType")}
                </p>
                <div className="flex flex-row gap-[8px] items-center">
                  <GlobalIcon
                    iconName="DisabledIcon"
                    size="XS"
                    color="var(--primary-color)"
                  />
                  <span className="BodyM font-medium text-[#424242] mr-auto flex-1">
                    {translate("calculator.label.ptia")}
                  </span>
                  {isDisability ? (
                    <>
                      <span className="BodyM text-[#A0A0A0]">
                        {translate("dashboard.calcualtor.notIncluded")}
                      </span>
                      <GlobalIcon
                        iconName="CircleX"
                        size="XS"
                        color="#EA5F5F"
                      />
                    </>
                  ) : (
                    <>
                      <span className="BodyM font-bold text-[var(--primary-color)]">
                        {translate("dashboard.calcualtor.included")}
                      </span>
                      <GlobalIcon
                        iconName="CircleCheckFilledIcon"
                        size="XS"
                        color="#018565"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="text-[#8E8E8E] BodyS flex flex-row items-center gap-[8px]">
                <GlobalIcon
                  iconName="InfoIcon"
                  size="XXS"
                  color="currentColor"
                />
                <span className="flex-1">
                  {translate("calculator.label.ptia.description")}
                </span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="mt-[24px] flex">
        <ButtonDS
          label={translate(`dashboard.buttons.${buttonCalculate}`)}
          rightIcon={showResults ? undefined : "ArrowRightIcon"}
          className="newDashboard-tariffifierCTA"
          onClick={() => handleSubmitOrganic(form, setValue)}
          buttonType={showResults ? "tertiary" : "primary"}
        />
      </div>
    </>
  );
}
