import { useState, useEffect, useContext } from "react";
import DashboardMain from "./DashboardMain";
import apiLeadPrice from "./../../api/request/apiLeadPrice";
import apiLeadSendDocument from "../../api/request/apiLeadSendDocument";
import {
  GUARANTEE,
  initialValuesFormCalcES,
  initialValuesFormCalcFR,
  initialValuesFormCalcIT,
  requiredFormCalcDash,
  requiredFormCalcDashFR,
  requiredFormCalcDashIT,
} from "../../utils/staticData";
import {
  validateFormES,
  validateFormFR,
  validateFormIT,
} from "../../utils/formValidator";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import HttpLeadRepository from "../../api/request/Lead/lead.service";
import { I18nContext } from "../../contexts/i18n.context";
import { GoogleContext } from "../../contexts/GoogleTagManager.context";
import useCapitalLimits from "../../hooks/useCapitalLimits.hook";
import apiLeadGetProfessions from "../../api/request/apiLeadGetProfessions";
import apiGetDownloadableFiles from "../../api/request/apiGetDownloadableFiles";
import { extractFromString } from "../../utils/extractFromString";
import { ulid } from "ulid";

export const emptyObjects = (obj) => Object.keys(obj).length;

export const validateBirthdate = (birthDate) => {
  const birth = birthDate.split("-");
  return birth
    .map((data) => (parseInt(data) < 10 ? "0" + parseInt(data) : data))
    .join("-");
};

export default function Dashboard() {
  const {
    broker,
    brokerId,
    leadId,
    setLeadId,
    setLoading,
    setShowNotification,
    setTokenVelogica,
    token,
  } = useContext(ContextGetlife);

  const { handleTrackerQuestion } = useContext(GoogleContext);

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  const {
    state: { translate },
  } = useContext(I18nContext);

  const leadRepository = new HttpLeadRepository(token);

  const initialValuesFormCalc = {
    es: initialValuesFormCalcES(translate),
    fr: initialValuesFormCalcFR,
    it: initialValuesFormCalcIT,
  };

  const [submittedValues, setSubmittedValues] = useState({});

  const [birthDate, setBirthDate] = useState(
    initialValuesFormCalc[DOMAIN].birthday
  );

  const [capital, setCapital] = useState(0);

  const [emailToSend, setEmailToSend] = useState("");

  const [error, setError] = useState([]);

  const [insurancePrice, setInsurancePrice] = useState(false);

  const [notOwner, setNotOwner] = useState(false);

  const [insuranceType, setInsuranceType] = useState();

  const [workingProfesionList, setWorkingProfesionList] = useState([]);

  const [shareText] = useState(translate("dashboard.button.share"));

  const [buttonCalculate, setButtonCalculate] = useState("calculate");

  const [isActiveRelatedEmail, setIsActiveRelatedEmail] = useState(false);

  const [showAlerts, setShowAlerts] = useState({
    maxCapital: false,
    disability: false,
    recalcMaxCapital: false,
    recalcDisability: false,
  });

  const [showModalSend, setShowModalSend] = useState(false);

  const [showResults, setShowResults] = useState(false);
  const { limits, traits, setBirthdate } = useCapitalLimits({
    setCapital,
    setShowNotification,
    translate,
    token,
  });

  const [downloadablePdfs, setDownloadablePdfs] = useState([]);

  const handleModalSend = () => {
    setShowModalSend(!showModalSend);
  };

  useEffect(() => {
    if (birthDate) {
      setBirthdate(validateBirthdate(birthDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthDate]);

  useEffect(() => {
    if (
      process.env.REACT_APP_DOMAIN_LOCALE === "fr" &&
      workingProfesionList.length === 0
    ) {
      apiLeadGetProfessions(token).then((response) => {
        const listFormat = [];
        response.value.map((item) => {
          item.label = item.value;
          item.value = item.key;
          listFormat.push(item);
        });

        setWorkingProfesionList(listFormat);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowResults(false);
    setShowAlerts({
      maxCapital: limits.maxCapital === 50000 && capital !== 0,
      disability:
        !traits.DISABILITY && limits.maxCapital !== 0 && capital !== 0,
      recalcMaxCapital:
        limits.maxCapital === 50000 && buttonCalculate === "recalculate",
      recalcDisability:
        !traits.DISABILITY &&
        limits.maxCapital !== 0 &&
        buttonCalculate === "recalculate",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limits, traits]);

  useEffect(() => {
    if (capital || insuranceType) {
      setShowResults(false);
    }
  }, [capital, insuranceType]);

  useEffect(() => {
    setLeadId("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInsurancePrice = (e) => setInsurancePrice(e);

  const getTemplates = (insuranceType) => {
    let templates;
    if (insuranceType === "DEVIS") {
      templates = ["devis"];
    } else {
      templates = ["offer-w-base"];
      if (insuranceType === "premium") {
        templates = ["offer-w-base", "ipa-any-cause-exclusions"];
      }
      if (insuranceType === "eg") {
        templates = ["serious-diseases-exclusions"];
      }
    }

    return templates;
  };

  const sendEmailDocument = async (email, text, insuranceType) => {
    const templates = getTemplates(insuranceType);
    const type = "general-a-exclusions";
    const resource = null;
    try {
      await apiLeadSendDocument(
        token,
        leadId,
        type,
        text,
        email,
        resource,
        templates
      );
      setShowModalSend(false);
      setShowNotification({
        message: translate("notification.email.correct"),
        status: true,
        type: "success",
      });
    } catch (e) {
      setShowNotification({
        message: translate("notification.email.error"),
        status: true,
        type: "error",
      });
    }
  };

  const cookies = document.cookie;

  const VWOID = extractFromString(cookies, "_vwo_uuid_v2");
  const GAID = extractFromString(cookies, "_ga");
  const FullStoryID = extractFromString(cookies, "fs_uid");
  const AT_GD = extractFromString(cookies, "at_gd");

  const updatePrice = async (leadId, token) => {
    const price = await apiLeadPrice(leadId, token);
    setInsurancePrice(price);
    return price;
  };

  const fetchDownloadables = async (leadId, insuranceType) => {
    const downloadablePdfs = await apiGetDownloadableFiles(
      leadId,
      insuranceType ?? submittedValues.insuranceType.value
    );
    setDownloadablePdfs(downloadablePdfs);
  };

  const fetchSubmitOrganicES = async (values) => {
    setSubmittedValues(values);
    const {
      capital,
      email,
      insuranceType,
      phone,
      referenceName,
      relatedEmail,
      market,
    } = values;
    const isNewLead = !leadId;
    let getLeadId;
    if (isNewLead) {
      getLeadId = ulid();
    } else {
      getLeadId = leadId;
    }
    setLeadId(getLeadId);
    try {
      const response = await leadRepository.postReducedOrganicEs(
        {
          leadId: getLeadId,
          birthDate,
          brokerId,
          capital: parseInt(capital),
          email: email || "",
          insuranceType: insuranceType["value"],
          phone: phone,
          referenceName: referenceName,
          relatedEmail: relatedEmail,
          token,
          market,
        },
        isNewLead ? "post" : "put"
      );
      if (response.msg) {
        throw new Error(response.msg);
      }
      await fetchDownloadables(getLeadId, insuranceType);
      setShowResults(getLeadId);
      setEmailToSend(email);
      const price = await updatePrice(getLeadId, token);
      setInsurancePrice(price);
      setLoading(false);
      setButtonCalculate("recalculate");
      if (price.maxCapital === true) setLeadId("");
      document.getElementById("result").scrollIntoView({ behavior: "smooth" });

      let tracking = false;
      let index = false;
      if (relatedEmail) {
        tracking = await leadRepository.getTracking(getLeadId);
        index = tracking ? tracking.length - 1 : 0;
      }

      const dataTracker = {
        leadUlid: getLeadId,
        campaign: tracking ? tracking[index].newCampaign : "broker-dashboard",
        googleAnalyticsId: tracking ? tracking[index].googleAnalyticsId : GAID,
        fullstoryId: tracking ? tracking[index].fullstoryId : FullStoryID,
        vwoId: tracking ? tracking[index].vwoId : VWOID,
        utmCampaign: tracking ? tracking[index].utmCampaign : brokerId,
        utmSource: tracking ? tracking[index].utmSource : "brokerapp",
        utmMedium: tracking
          ? tracking[index].utmMedium
          : broker.brokerageId.toString(),
        utmContent: tracking ? tracking[index].utmContent : null,
        utmTerm: tracking ? tracking[index].utmTerm : null,
        utmTest: tracking ? tracking[index].utmTest : null,
        gclid: tracking ? tracking[index].gclid : null,
        fbclid: tracking ? tracking[index].fbclid : null,
        tid: tracking ? tracking[index].tid : null,
        atGd: tracking ? tracking[index].atGd : AT_GD,
        entryPage: tracking ? tracking[index].utmEntryPage : null,
        referrerUrl: tracking ? tracking[index].utmReferrerUrl : null,
        ABTestVersion: tracking ? tracking[index].aBTestVersion : null,
      };

      await leadRepository.saveTracking({ data: dataTracker });

      const eventData = {
        calculatedCapital: parseInt(capital),
        calculatedPremium: parseFloat(price.monthlyPrice),
        coverage: GUARANTEE[insuranceType.value],
        email: email,
        leadId: getLeadId,
        page: window.location.href,
        phone: phone,
        brokerEmployeeId: brokerId,
        brokerId: broker.brokerageId,
        utm_source: tracking ? tracking[index].utmSource : "brokerapp",
        utm_campaign: tracking ? tracking[index].utmCampaign : brokerId,
        utm_medium: tracking
          ? tracking[index].utmMedium
          : broker.brokerageId.toString(),
        platform: "Broker",
        entryPage: tracking ? tracking[index].utmEntryPage : null,
        referrerUrl: tracking ? tracking[index].utmReferrerUrl : null,
        ABTestVersion: tracking ? tracking[index].aBTestVersion : null,
      };

      if (response.isFirstIncoming) {
        const event = "leadCreated";

        handleTrackerQuestion({
          type: "FS",
          event,
          data: {
            birthDate: new Date(birthDate),
            ...eventData,
          },
        });

        handleTrackerQuestion({
          type: "GA",
          data: {
            event,
            birthDate: birthDate,
            ...eventData,
          },
        });
      }
      const event = "calculatorQuoteGenerated";

      handleTrackerQuestion({
        type: "FS",
        event,
        data: {
          birthDate: new Date(birthDate),
          ...eventData,
        },
      });
      handleTrackerQuestion({
        type: "GA",
        data: {
          event,
          birthDate: birthDate,
          ...eventData,
        },
      });
    } catch (e) {
      const catchedError = e.toString();
      if (catchedError.includes("already exists")) {
        setError([
          {
            field: "email",
            message: translate("calculator.error.repeatedEmail"),
          },
        ]);
      }
      if (isNewLead) setLeadId("");
      setLoading(false);
    }
  };

  const fetchSubmitOrganicFR = async (values) => {
    setSubmittedValues(values);
    const {
      capital,
      email,
      gender,
      phone,
      referenceName,
      relatedEmail,
      zipCode,
      smoker,
      workingProfesion,
      workHeights,
      workDistance,
      workTools,
    } = values;
    try {
      const response = await leadRepository.postOrganic(
        {
          birthdate: birthDate,
          brokerId,
          capital: parseInt(capital),
          email: email || "",
          gender: gender["value"],
          phone: phone || null,
          referenceName: referenceName,
          relatedEmail: relatedEmail,
          token,
          zipCode: zipCode,
          nicotine: smoker["value"],
          scorProfessionId: workingProfesion["value"],
          distanceUpper25000KmPerYear:
            workDistance["value"] === "true" ? true : false,
          heightMoreThan3m: workHeights["value"] === "true" ? true : false,
          loadMoreThan5Kg: workTools["value"] === "true" ? true : false,
        },
        false
      );
      const { leadId, notOwner } = response;
      if (!leadId) {
        throw new Error(response);
      }
      fetchDownloadables(leadId, "premium");
      setTokenVelogica("");
      setNotOwner(notOwner);
      setLeadId(leadId);
      setShowResults(leadId);
      setEmailToSend(response.email);
      const price = await updatePrice(leadId, token);
      setInsurancePrice(price);
      setLoading(false);
      setButtonCalculate("recalculate");
      document.getElementById("result").scrollIntoView({ behavior: "smooth" });

      let tracking = false;
      let index = false;
      if (relatedEmail) {
        tracking = await leadRepository.getTracking(leadId);
        index = tracking ? tracking.length - 1 : 0;
      }

      const dataTracker = {
        leadUlid: leadId,
        campaign: tracking ? tracking[index].newCampaign : "broker-dashboard",
        googleAnalyticsId: tracking ? tracking[index].googleAnalyticsId : GAID,
        fullstoryId: tracking ? tracking[index].fullstoryId : FullStoryID,
        vwoId: tracking ? tracking[index].vwoId : VWOID,
        utmCampaign: tracking ? tracking[index].utmCampaign : brokerId,
        utmSource: tracking ? tracking[index].utmSource : "brokerapp",
        utmMedium: tracking
          ? tracking[index].utmMedium
          : broker.brokerageId.toString(),
        utmContent: tracking ? tracking[index].utmContent : null,
        utmTerm: tracking ? tracking[index].utmTerm : null,
        utmTest: tracking ? tracking[index].utmTest : null,
        gclid: tracking ? tracking[index].gclid : null,
        fbclid: tracking ? tracking[index].fbclid : null,
        tid: tracking ? tracking[index].tid : null,
        atGd: tracking ? tracking[index].atGd : AT_GD,
        entryPage: tracking ? tracking[index].utmEntryPage : null,
        referrerUrl: tracking ? tracking[index].utmReferrerUrl : null,
        ABTestVersion: tracking ? tracking[index].aBTestVersion : null,
      };

      await leadRepository.saveTracking({ data: dataTracker });

      const eventData = {
        calculatedCapital: parseInt(capital),
        calculatedPremium: parseFloat(price.monthlyPrice),
        coverage: GUARANTEE[price.insuranceType],
        email: email,
        gender: gender.value,
        intent: "loan",
        leadId: leadId,
        page: window.location.href,
        phone: phone,
        postalCode: zipCode,
        brokerEmployeeId: brokerId,
        brokerId: broker.brokerageId,
        utm_source: tracking ? tracking[index].utmSource : "brokerapp",
        utm_campaign: tracking ? tracking[index].utmCampaign : brokerId,
        utm_medium: tracking ? tracking[index].utmMedium : broker.brokerageId,
        platform: "Broker",
        entryPage: tracking ? tracking[index].utmEntryPage : null,
        referrerUrl: tracking ? tracking[index].utmReferrerUrl : null,
        ABTestVersion: tracking ? tracking[index].aBTestVersion : null,
      };

      if (response.isFirstIncoming) {
        const event = "leadCreated";

        handleTrackerQuestion({
          type: "FS",
          event,
          data: {
            birthDate: new Date(birthDate),
            ...eventData,
          },
        });

        handleTrackerQuestion({
          type: "GA",
          data: {
            event,
            birthDate: birthDate,
            ...eventData,
          },
        });
      }
      const event = "calculatorQuoteGenerated";

      handleTrackerQuestion({
        type: "FS",
        event,
        data: {
          birthDate: new Date(birthDate),
          ...eventData,
        },
      });
      handleTrackerQuestion({
        type: "GA",
        data: {
          event,
          birthDate: birthDate,
          ...eventData,
        },
      });
    } catch (e) {
      const errorCatched = e.toString();
      if (errorCatched.includes("Lead with email")) {
        setError([
          {
            field: "email",
            message: translate("calculator.error.repeatedEmail"),
          },
        ]);
      }
      setLoading(false);
    }
  };

  const fetchSubmitOrganicIT = async (values) => {
    setSubmittedValues(values);
    const {
      capital,
      email,
      gender,
      phone,
      referenceName,
      relatedEmail,
      zipCode,
      smoker,
      intention,
    } = values;
    try {
      const response = await leadRepository.postOrganic(
        {
          birthdate: birthDate,
          brokerId,
          capital: parseInt(capital),
          email: email || "",
          gender: gender["value"],
          phone: phone || null,
          referenceName: referenceName,
          relatedEmail: relatedEmail,
          token,
          zipCode: zipCode,
          nicotine: smoker["value"],
          intention: intention["value"],
        },
        false
      );
      const { leadId, notOwner } = response;
      if (!leadId) {
        throw new Error(response);
      }
      setNotOwner(notOwner);
      setLeadId(leadId);
      setShowResults(leadId);
      setEmailToSend(response.email);
      const price = await updatePrice(leadId, token);
      setInsurancePrice(price);
      setLoading(false);
      setButtonCalculate("recalculate");
      document.getElementById("result").scrollIntoView({ behavior: "smooth" });

      let tracking = false;
      let index = false;
      if (relatedEmail) {
        tracking = await leadRepository.getTracking(leadId);
        index = tracking ? tracking.length - 1 : 0;
      }

      const dataTracker = {
        leadUlid: leadId,
        campaign: tracking ? tracking[index].newCampaign : "broker-dashboard",
        googleAnalyticsId: tracking ? tracking[index].googleAnalyticsId : GAID,
        fullstoryId: tracking ? tracking[index].fullstoryId : FullStoryID,
        vwoId: tracking ? tracking[index].vwoId : VWOID,
        utmCampaign: tracking ? tracking[index].utmCampaign : brokerId,
        utmSource: tracking ? tracking[index].utmSource : "brokerapp",
        utmMedium: tracking
          ? tracking[index].utmMedium
          : broker.brokerageId.toString(),
        utmContent: tracking ? tracking[index].utmContent : null,
        utmTerm: tracking ? tracking[index].utmTerm : null,
        utmTest: tracking ? tracking[index].utmTest : null,
        gclid: tracking ? tracking[index].gclid : null,
        fbclid: tracking ? tracking[index].fbclid : null,
        tid: tracking ? tracking[index].tid : null,
        atGd: tracking ? tracking[index].atGd : AT_GD,
        entryPage: tracking ? tracking[index].utmEntryPage : null,
        referrerUrl: tracking ? tracking[index].utmReferrerUrl : null,
        ABTestVersion: tracking ? tracking[index].aBTestVersion : null,
      };

      await leadRepository.saveTracking({ data: dataTracker });

      const eventData = {
        calculatedCapital: parseInt(capital),
        calculatedPremium: parseFloat(price.monthlyPrice),
        coverage: GUARANTEE[price.insuranceType],
        email: email,
        gender: gender.value,
        intent: "loan",
        leadId: leadId,
        page: window.location.href,
        phone: phone,
        postalCode: zipCode,
        brokerEmployeeId: brokerId,
        brokerId: broker.brokerageId,
        utm_source: tracking ? tracking[index].utmSource : "brokerapp",
        utm_campaign: tracking ? tracking[index].utmCampaign : brokerId,
        utm_medium: tracking ? tracking[index].utmMedium : broker.brokerageId,
        platform: "Broker",
        entryPage: tracking ? tracking[index].utmEntryPage : null,
        referrerUrl: tracking ? tracking[index].utmReferrerUrl : null,
        ABTestVersion: tracking ? tracking[index].aBTestVersion : null,
      };

      if (response.isFirstIncoming) {
        const event = "leadCreated";

        handleTrackerQuestion({
          type: "FS",
          event,
          data: {
            birthDate: new Date(birthDate),
            ...eventData,
          },
        });

        handleTrackerQuestion({
          type: "GA",
          data: {
            event,
            birthDate: birthDate,
            ...eventData,
          },
        });
      }
      const event = "calculatorQuoteGenerated";

      handleTrackerQuestion({
        type: "FS",
        event,
        data: {
          birthDate: new Date(birthDate),
          ...eventData,
        },
      });
      handleTrackerQuestion({
        type: "GA",
        data: {
          event,
          birthDate: birthDate,
          ...eventData,
        },
      });
    } catch (e) {
      const errorCatched = e.toString();
      if (errorCatched.includes("Lead with email")) {
        setError([
          {
            field: "email",
            message: translate("calculator.error.repeatedEmail"),
          },
        ]);
      }
      setLoading(false);
    }
  };

  const validateSubmitForm = async (values) => {
    const error = await validateFormES({
      values,
      requiredFields: requiredFormCalcDash,
      dashBoard: true,
      translate,
    });
    if (error) setLoading(false);
    return error;
  };

  const validateSubmitFormIT = async (values) => {
    const error = await validateFormIT({
      values,
      requiredFields: requiredFormCalcDashIT,
      dashBoard: true,
      translate,
    });
    if (error) setLoading(false);
    return error;
  };

  const validateSubmitFormFR = async (values) => {
    const error = await validateFormFR({
      values,
      requiredFields: requiredFormCalcDashFR,
      dashBoard: true,
      translate,
    });
    if (error) setLoading(false);
    return error;
  };

  const handleSubmitOrganic = async (values) => {
    setLoading(true);
    if (DOMAIN === "es") {
      const errorData = await validateSubmitForm(values);
      if (!traits.DEATH) {
        setShowNotification({
          message: translate("errors.limits.ageRange"),
          status: true,
          type: "error",
          autoHideDuration: 5000,
        });
      }
      setError(errorData);
      if (!errorData) {
        await fetchSubmitOrganicES(values);
      }
    } else if (DOMAIN === "fr") {
      const errorData = await validateSubmitFormFR(values);
      setError(errorData);
      if (!errorData) {
        await fetchSubmitOrganicFR(values);
      }
    } else if (DOMAIN === "it") {
      const errorData = await validateSubmitFormIT(values);
      setError(errorData);
      if (!errorData) {
        await fetchSubmitOrganicIT(values);
      }
    }
  };

  return (
    <DashboardMain
      broker={broker}
      buttonCalculate={buttonCalculate}
      capital={capital}
      capitalLimits={limits}
      emailToSend={emailToSend}
      error={error}
      isActiveRelatedEmail={isActiveRelatedEmail}
      handleInsurancePrice={handleInsurancePrice}
      handleModalSend={handleModalSend}
      handleSubmitOrganic={handleSubmitOrganic}
      initialValues={initialValuesFormCalc[DOMAIN]}
      insurancePrice={insurancePrice}
      notOwner={notOwner}
      sendEmailDocument={sendEmailDocument}
      setBirthDate={setBirthDate}
      setCapital={setCapital}
      setIsActiveRelatedEmail={setIsActiveRelatedEmail}
      setInsuranceType={setInsuranceType}
      setNotOwner={setNotOwner}
      setShowAlerts={setShowAlerts}
      setButtonCalculate={setButtonCalculate}
      setShowModalSend={setShowModalSend}
      setShowResults={setShowResults}
      shareText={shareText}
      showAlerts={showAlerts}
      showModalSend={showModalSend}
      showResults={showResults}
      token={token}
      traits={traits}
      workingProfesionList={workingProfesionList}
      downloadablePdfs={downloadablePdfs}
      submittedValues={submittedValues}
      updatePrice={updatePrice}
      fetchDownloadables={fetchDownloadables}
      setLeadId={setLeadId}
      setError={setError}
    />
  );
}
