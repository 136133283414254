import { createContext, useContext, useEffect, useState } from "react";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { SQLBoundariesResponse } from "../../../api/request/Lead/Model/Response/SQLBoundariesResponse";
import { ulid } from "ulid";
import OrganicSQLRequest from "../../../api/request/Lead/Model/Request/OrganicSQLRequest";
import { DateTime } from "luxon";
import { I18nContext } from "../../../contexts/i18n.context";
import REGEX from "../../../utils/regExp";
import { SQLPricingResponse } from "../../../api/request/Lead/Model/Response/SQLPricingResponse";
import { SQLRulesResponse } from "../../../api/request/Lead/Model/Response/SQLRulesResponse";
import OrganicSQLResponse from "../../../api/request/Lead/Model/Response/OrganicSQLResponse";
import { useNavigate } from "react-router";

interface MultiproductProviderI {
  children: React.ReactNode;
}

interface BasicFormI {
  email?: string;
  birthDate?: string;
  capital?: number;
  phone?: string;
  referenceName?: string;
}

interface GuaranteeProperties {
  active: boolean;
  capital: number;
}

export interface GuaranteeFormI {
  readonly DEATH: Readonly<{ active: true } & GuaranteeProperties>;
  DISABILITY: GuaranteeProperties;
  TEMPORARY_DISABILITY: GuaranteeProperties;
  SERIOUS_ILLNESS: GuaranteeProperties;
  ACCIDENT_DISABILITY: GuaranteeProperties;
  TRAFFIC_ACCIDENT_DISABILITY: GuaranteeProperties;
  ACCIDENT_DEATH: GuaranteeProperties;
  TRAFFIC_ACCIDENT_DEATH: GuaranteeProperties;
}

type BasicFormErrorsI = {
  [key in keyof BasicFormI]?: string;
};

type BasicFieldsModifiedI = {
  [key in keyof BasicFormI]?: boolean;
};

export interface BasicBoundariesI extends Partial<SQLBoundariesResponse> {}

type EditBasicFormT = <K extends keyof BasicFormI>(
  field: K,
  value: BasicFormI[K]
) => void;

export type ToggleGuaranteeActiveT = (key: keyof GuaranteeFormI) => void;

export type UpdateGuaranteeCapitalT = (
  key: keyof GuaranteeFormI,
  capital: number
) => void;

type ToggleBasicFieldModifiedT = <K extends keyof BasicFormI>(field: K) => void;

interface MultiproductContextValuesI {
  basicForm: BasicFormI;
  editBasicForm: EditBasicFormT;
  basicBoundaries: BasicBoundariesI;
  leadId: string | undefined;
  postOrganic: () => void;
  basicFormError: BasicFormErrorsI;
  toggleBasicFieldModified: ToggleBasicFieldModifiedT;
  pricing: SQLPricingResponse | undefined;
  rules: SQLRulesResponse | undefined;
  guaranteeForm: GuaranteeFormI;
  toggleGuaranteeActive: ToggleGuaranteeActiveT;
  updateGuaranteeCapital: UpdateGuaranteeCapitalT;
  loading: boolean;
  updateDeathCapital: (capital: number) => void;
  navigateToQuestions: () => void;
}

const MultiproductContext = createContext({} as MultiproductContextValuesI);

const MultiproductProvider = ({ children }: MultiproductProviderI) => {
  const {
    state: { translate },
  } = useContext(I18nContext);
  const navigate = useNavigate();

  const { token, brokerId, leadId, setLeadId } = useContext(ContextGetlife);
  const leadRepository = new HttpLeadRepository(token);

  const [basicForm, setBasicForm] = useState<BasicFormI>({
    email: undefined,
    birthDate: undefined,
    capital: undefined,
    phone: undefined,
    referenceName: undefined,
  });
  const [guaranteeForm, setGuaranteeForm] = useState<GuaranteeFormI>({
    DEATH: {
      active: true,
      capital: 175000,
    },
    DISABILITY: {
      active: false,
      capital: 0,
    },
    TEMPORARY_DISABILITY: {
      active: false,
      capital: 0,
    },
    SERIOUS_ILLNESS: {
      active: false,
      capital: 0,
    },
    ACCIDENT_DISABILITY: {
      active: false,
      capital: 0,
    },
    TRAFFIC_ACCIDENT_DISABILITY: {
      active: false,
      capital: 0,
    },
    ACCIDENT_DEATH: {
      active: false,
      capital: 0,
    },
    TRAFFIC_ACCIDENT_DEATH: {
      active: false,
      capital: 0,
    },
  });
  const [basicFormError, setBasicFormError] = useState<BasicFormErrorsI>({});
  const [basicFieldsModified, setBasicFieldsModified] =
    useState<BasicFieldsModifiedI>({});
  const [basicBoundaries, setBasicBoundaries] = useState<BasicBoundariesI>({
    min: undefined,
    max: undefined,
    available: false,
  });
  const [pricing, setPricing] = useState<SQLPricingResponse | undefined>(
    undefined
  );
  const [rules, setRules] = useState<SQLRulesResponse | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const editBasicForm: EditBasicFormT = (field, value) => {
    setBasicForm({ ...basicForm, [field]: value });
  };

  const validateBasicForm = (forceValidation = false) => {
    const _error: any = {};
    const { birthDate, capital, email, phone, referenceName } = basicForm;
    if (basicFieldsModified["birthDate"] || forceValidation) {
      if (birthDate) {
        const date = DateTime.fromISO(birthDate);
        const now = DateTime.now();
        const years = now.diff(date, "years").years;
        const isValid = years >= 18 && years <= 64;
        if (!isValid) {
          _error["birthDate"] = translate(
            "dashboard.multiproduct.validate.dateOutOfRange"
          );
        }
      } else {
        _error["birthDate"] = translate(
          "dashboard.multiproduct.validate.isMandatory"
        );
      }
    }
    if (basicFieldsModified["email"] && email) {
      const isValidEmail = email.match(REGEX["EMAIL"]);
      if (!isValidEmail) {
        _error["email"] = translate(
          "dashboard.multiproduct.validate.wrongEmail"
        );
      }
    }

    if (basicFieldsModified["phone"] && phone) {
      const isValidPhone = phone.match(REGEX["PHONE"]);
      if (!isValidPhone) {
        _error["phone"] = translate(
          "dashboard.multiproduct.validate.wrongPhone"
        );
      }
    }

    if (
      basicFieldsModified["referenceName"] ||
      basicFieldsModified["phone"] ||
      basicFieldsModified["email"] ||
      forceValidation
    ) {
      if (!referenceName && !phone && !email) {
        _error["referenceName"] = translate(
          "dashboard.multiproduct.error.atLeastOneMandatory"
        );
        _error["phone"] = translate(
          "dashboard.multiproduct.error.atLeastOneMandatory"
        );
        _error["email"] = translate(
          "dashboard.multiproduct.error.atLeastOneMandatory"
        );
      }
    }

    setBasicFormError(_error);
    return _error;
  };

  const toggleBasicFieldModified: ToggleBasicFieldModifiedT = (field) => {
    if (!basicFieldsModified[field]) {
      setBasicFieldsModified({ ...basicFieldsModified, [field]: true });
    }
  };

  const getBoundaries = async () => {
    leadRepository
      .getSQLBoundaries({ birthdate: basicForm["birthDate"] as string })
      .then((response: SQLBoundariesResponse) => {
        setBasicBoundaries(response);
        if (response.max && response.available) {
          if (!basicForm["capital"]) {
            editBasicForm("capital", response.max / 2);
          } else {
            const capitalIsOutOfRange =
              basicForm["capital"] < response.min ||
              basicForm["capital"] > response.max;
            if (capitalIsOutOfRange) {
              editBasicForm("capital", response.max / 2);
            }
          }
        }
      })
      .catch(() => {
        setBasicBoundaries({ min: 0, max: 0, available: false });
        editBasicForm("capital", undefined);
      });
  };

  const postOrganic = async () => {
    const errors = validateBasicForm(true);
    const hasErrors = Object.keys(errors).length > 0;
    if (!hasErrors) {
      let _leadId = "";
      let method: "post" | "put" = "post";

      if (!leadId) {
        _leadId = ulid();
        method = "post";
        setLeadId(_leadId);
      } else {
        _leadId = leadId;
        method = "put";
      }
      const data: OrganicSQLRequest = {
        leadId: _leadId,
        brokerId: brokerId,
        ...basicForm,
      } as OrganicSQLRequest;

      setLoading(true);

      leadRepository
        .postReducedOrganicSQL(data, method)
        .then(async (response: OrganicSQLResponse) => {
          Promise.all([getPricing(_leadId), getRules(_leadId)]).then(() => {
            setLoading(false);
          });
        })
        .catch(() => {
          // If an error occurs when the lead has not been created, clear the lead id to keep the method as post instead of put
          if (method === "post") setLeadId("");
          setLoading(false);
        });
    }
  };

  const getPricing = async (_leadId: string) => {
    await leadRepository
      .getSQLPricing(_leadId)
      .then((response: SQLPricingResponse) => {
        setPricing(response);
      });
  };

  const getRules = async (_leadId: string) => {
    await leadRepository
      .getSQLRules(_leadId)
      .then((response: SQLRulesResponse) => {
        setRules(response);
      });
  };

  const toggleGuaranteeActive: ToggleGuaranteeActiveT = (
    key: keyof GuaranteeFormI
  ) => {
    setGuaranteeForm({
      ...guaranteeForm,
      [key]: { ...guaranteeForm[key], active: !guaranteeForm[key].active },
    });
  };

  const updateGuaranteeCapital: UpdateGuaranteeCapitalT = (
    key: keyof GuaranteeFormI,
    capital: number
  ) => {
    setGuaranteeForm({
      ...guaranteeForm,
      [key]: { ...guaranteeForm[key], capital },
    });
  };

  const updateDeathCapital = async (capital: number) => {
    editBasicForm("capital", capital);
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 200));
    setLoading(false);
  };

  const navigateToQuestions = async () => {
    if (leadId) {
      setLoading(true);
      await leadRepository.questionsByLeadId(leadId);
      setLoading(false);
      navigate(`/questions`);
    }
  };

  useEffect(() => {
    if (basicForm["birthDate"]) {
      getBoundaries();
    }
  }, [basicForm.birthDate]);

  useEffect(() => {
    validateBasicForm();
  }, [basicForm, basicFieldsModified]);

  useEffect(() => {
    setLeadId("");
  }, []);

  return (
    <MultiproductContext.Provider
      value={{
        basicForm,
        editBasicForm,
        basicBoundaries,
        leadId,
        postOrganic,
        basicFormError,
        toggleBasicFieldModified,
        pricing,
        rules,
        guaranteeForm,
        toggleGuaranteeActive,
        updateGuaranteeCapital,
        loading,
        updateDeathCapital,
        navigateToQuestions,
      }}
    >
      {children}
    </MultiproductContext.Provider>
  );
};

export { MultiproductProvider, MultiproductContext };
